import React from 'react';
// import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <iframe id="AccentureAverda"
      title="Accenture Averda"
      frameborder="0" allowfullscreen style={{position:'absolute', bottom:0, left:0, width:100+'%' , height:100+'%' }}
      src="https://routinghub.com/static/tools/route.html?task_id=3HNn55M5tiTrQHKcRjyJwE&apikey=0jvawNDbh3zUvcre3OBOQR"></iframe>

  );
}

export default App;
